// extracted by mini-css-extract-plugin
export var top_project_content = "project-details-module--top_project_content--HW1Gu";
export var project_content = "project-details-module--project_content--2SAyg";
export var project_actions = "project-details-module--project_actions--1QxuW";
export var project_live = "project-details-module--project_live--3LEFq";
export var project_github = "project-details-module--project_github--3VYJS";
export var heading = "project-details-module--heading--3qaNf";
export var slideWrapper = "project-details-module--slideWrapper--2tpzK";
export var slideshow = "project-details-module--slideshow--15rRy";
export var slideshowSlider = "project-details-module--slideshowSlider--yh_ZM";
export var slide = "project-details-module--slide--2y26h";
export var slideshowDots = "project-details-module--slideshowDots--1UqlO";
export var slideshowDot = "project-details-module--slideshowDot--dODOB";
export var btnActive = "project-details-module--btnActive--1YQ0C";
export var show = "project-details-module--show--2c1u_";
export var project_details = "project-details-module--project_details--1I9NI";